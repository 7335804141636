'use strict';
/* jslint browser: true */

(function(scope, util) {
  util.addEventListener(document, 'touchstart', function() {
    if(window.localStorage.getItem('touch') === null) {
      window.localStorage.setItem('touch', true);
    }
    return;
  });

}(window.app, window.app.util));