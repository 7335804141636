'use strict';
/* jslint browser: true */

(function(scope, util) {

  scope.Filter = function (ops) {
    var config      = {
          containerId   : ops.containerId || 'filter-this',
          contentClass  : ops.contentClass || 'item',
          dataAttribute : ops.dataAttribute || 'data-name',
          inputId       : ops.inputId || 'filter'
        },
        containerEl = document.getElementById(config.containerId),
        filterEl    = document.getElementById(config.inputId),
        data        = [],
        items,
        queryStr;


    var getData = function() {
      util.forEach(items, function(item, i){
        data.push(item.getAttribute(config.dataAttribute).toLowerCase());
      });
    };

    var query = function(e) {
      if((e.keyCode >= 65 && e.keyCode <= 90) || e.keyCode === 32 || e.keyCode === 8 || e.keyCode === 46) {
        queryStr = filterEl.value.toLowerCase();
        for(var i = 0, l = items.length; i < l; i++) {
          if(data[i].indexOf(queryStr) === -1) {
            items[i].style.display = 'none';
          } else {
            items[i].style.display = 'block';
          }
        }
      }
    };

    var reinit = function() {
      for(var i = 0, l = items.length; i < l; i++) {
        items[i].style.display = 'block';
      }
      filterEl.value = '';
      if(!util.isTouch()) filterEl.focus();
    };

    if(!!containerEl && !!filterEl) {
      items = containerEl.getElementsByClassName(config.contentClass);
      if(items.length !== 0) {
        getData();
        util.addEventListener(filterEl, 'keyup', query);
        if(!util.isTouch()) filterEl.focus();
      } else {
        console.log('no items found');
      }
    }

    // PUBLIC
    this.reinit = reinit;
  };
}(window.app, window.app.util));