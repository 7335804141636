'use strict';
/* jslint browser: true */

(function(scope, util) {

  scope.Form = function(ops) {
    
    var validate = function(form) {
      var complete = true;
      var formatted = true;
      var focussed = false;
      var elements = form.elements;
      //clear errors
      util.forEach(form.getElementsByClassName('error'), function(el) {
        util.removeClass(el, 'visible');
      });
      util.forEach(form.elements, function(el) {
        util.removeClass(el, 'error');
      });
      //----------
      util.forEach(elements, function(el){
        if(el.required && el.value.length === 0 ) {
          util.addClass(el, 'error');
          if (!focussed) {
            el.focus();
            focussed = true;
          }
          complete = false;
        } else if(el.type === 'email' && !(/\S+@\S+\.\S+/.test(el.value)) ) {
          util.addClass(el, 'error');
          if (!focussed) {
            el.focus();
            focussed = true;
          }
          formatted = false;
        }
      });
      if (!complete) {
        util.addClass(document.getElementById('incomplete'), 'visible');
      } 
      if (!formatted) {
        util.addClass(document.getElementById('invalid-email'), 'visible');
      }
      return complete && formatted;
    };

    var beforeSend = function(form) {
      util.addClass(form.getElementsByClassName('cover')[0], 'loading');
    };

    var onerror = function(form) {
      util.addClass(form.querySelector('#server'), 'visible');
    };

    var onload = function(form, request) {
      var cover = form.getElementsByClassName('cover')[0];
      if(request.status === 500) {
        util.removeClass(cover, 'loading');
        util.addClass(document.getElementById('server'), 'visible');
      } else if(request.status === 200){
        form.reset();
        util.removeClass(cover, 'loading');
        util.addClass(cover, 'success');
        setTimeout(function() {
          util.removeClass(cover, 'success');
        }, 1500);
      }
    };

    var config = {
      form       : ops.form || null,
      validate   : ops.validate || validate,
      onerror    : ops.onerror || onerror,
      onload     : ops.onload || onload,
      beforeSend : ops.beforeSend || beforeSend
    };

    var hasHtml5Validation = function() {
      return 'reportValidity' in document.createElement('form') && typeof document.createElement( 'input' ).checkValidity == 'function'; 
    };

    var init = function() {
      util.addEventListener(config.form, 'submit', function(e){
        e.preventDefault();
        var form    = config.form,
            url     = form.action,
            request = new XMLHttpRequest(),
            valid   = true, 
            params;
        if (!hasHtml5Validation()) { 
          valid = config.validate(form);
        }
        if (valid) {
          params  = [].filter.call(form.elements, function(el) {
                      return el.type.toLowerCase() !== 'submit';
                    }).map(function(el) {
                        return encodeURIComponent(el.name) + '=' + encodeURIComponent(el.value);  
                    }).join('&');

          request.open('POST', url, true);
          request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
          request.onerror = function(e) {
            config.onerror(form);
          };
          request.onload = function(e) {
            config.onload(form, request);
          };
          config.beforeSend(form);
          request.send(params);
        }
      });
    };

    if(!!config.form) {
      init();
    }
  };

}(window.app, window.app.util));