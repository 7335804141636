'use strict';
/* jslint browser: true */

(function(scope, util) {

  scope.Zoomer = function (ops) {
    var config = {
          triggerClass      : ops.triggerClass || 'zoom',
          zoomWrapperId     : ops.zoomWrapperId || 'zoomer-wrapper',
          zoomDivId         : ops.zoomDivId || 'zoomer',
          closeId           : ops.closeId || 'zoom-close',
          loadedClass       : ops.loadedClass || 'loaded',
          zoomActiveClass   : ops.zoomActiveClass || 'active',
          beforeOpen        : ops.beforeOpen || null,
          afterClose        : ops.afterClose || null,
          getNewSourceFn    : ops.getNewSourceFn || null
        },
        wrapperNode     = document.getElementById(config.zoomWrapperId),
        zoomerNode      = document.getElementById(config.zoomDivId), 
        zoomedImg       = null,
        containerRect   = null,
        zoomedImageRect = null;

    var zoomerIsActive = function() {
      return wrapperNode && util.hasClass(wrapperNode, config.zoomActiveClass);
    };

    var resize = function() {
      if(zoomerIsActive()) {
        containerRect = zoomerNode.getBoundingClientRect(); 
        zoomedImageRect = zoomedImg.getBoundingClientRect(); 
      }
    };



    
    var contract = function() {
      util.removeClass(this, 'fill-horizontal');
      util.removeClass(this, 'fill-vertical');
      util.addEventListener(zoomedImg, 'click', expand);
    };

    var expand = function() {
      var contAr =  containerRect.width / containerRect.height;
      var imgAr = zoomedImageRect.width / zoomedImageRect.height;
      if(imgAr <= contAr) {
        util.addClass(this, 'fill-horizontal');
      } else {
        util.addClass(this, 'fill-vertical');
      }
      util.removeEventListener(this, 'click', expand);
      util.addEventListener(this, 'click', contract);
    };

    var initNewImage = function() {
      var newImage = this, oldImage;
      this.onload = null; // to prevent onload being called unnecessarily again
      setTimeout(function() {   
        oldImage = zoomerNode.getElementsByTagName('img')[0];
        zoomedImg = zoomerNode.appendChild(newImage);
        zoomerNode.removeChild(oldImage);
        util.addClass(wrapperNode, config.loadedClass);
        containerRect = zoomerNode.getBoundingClientRect(); 
        zoomedImageRect = zoomedImg.getBoundingClientRect();
        if(!util.isTouch()) util.addEventListener(zoomedImg, 'click', expand);
        //util.addEventListener(zoomerNode, 'mousemove', pan);
        //util.addEventListener(zoomerNode, 'touchmove', scrollImg);
      }, 0);
    };

    var loadNewSource = function(src) {
      var request = new XMLHttpRequest();
      request.open('GET', src, true);
      request.onload = function() {
        if(request.status >= 200 && request.status < 400) {
          var i = new Image();
          i.src = src;
          i.onload = initNewImage;
        } else {
          console.log('Server returned error code: ' + request.status);
        }
      };
      request.onerror = function() {
        console.log('Connection error');
      };
      request.send();
    };

    var close = function() {
      var imgNode;
      if(zoomerIsActive()) {
        util.removeClass(wrapperNode, config.zoomActiveClass);
        imgNode = zoomerNode.getElementsByTagName('img')[0];
        imgNode.setAttribute('src', '');
        util.removeClass(imgNode, 'fill-horizontal');
        util.removeClass(imgNode, 'fill-vertical');
        zoomerNode.scrollLeft = 0;
        zoomerNode.scrollTop = 0;
        util.removeClass(wrapperNode, config.loadedClass);
        if(config.afterClose) config.afterClose();
      }
    };

    var zoom = function(el) {
      var currentSource, newSource, elToZoom = el;
      if(!zoomerIsActive() && elToZoom) {
        currentSource = elToZoom ? elToZoom.getAttribute('src') : null;
        newSource = config.getNewSourceFn ? config.getNewSourceFn(currentSource) : currentSource;
        zoomerNode.getElementsByTagName('img')[0].setAttribute('src', currentSource);
        loadNewSource(newSource);
        if(config.beforeOpen) config.beforeOpen();
        util.addClass(wrapperNode, config.zoomActiveClass);
      } 
    };

    var init = function() {
      util.addEventListener(document.getElementById(config.closeId), 'click', close);
      util.addEventListener(document, 'keydown', function(e){
        if(e.keyCode === 27 && zoomerIsActive()) {
          close();
        }
      });
      util.addEventListener(window, 'resize', resize);
    };

    if(!!wrapperNode && !!zoomerNode) init();


    /* PUBLIC */
    this.zoom = zoom;
    this.close = close;
  };

}(window.app, window.app.util));