'use strict';
/* jslint browser: true */
(function(scope, util) {

  scope.Slideshow = function(tags) {

    var config = {
          containerId    : tags.containerId || 'test',
          slideClass     : tags.slideClass || 'slide',
          activeClass    : tags.activeClass || 'active',
          nextId         : tags.nextId || 'next',
          prevId         : tags.prevId || 'prev',
          counterId      : tags.counterId || 'count',
          capSourceClass : tags.capSourceClass || 'caption',
          capDestId      : tags.capDestId || 'cap',
          copyCaptions   : tags.copyCaptions || true,
          startAtIndex   : tags.startAtIndex || 0,
          beforeChange   : tags.beforeChange || null,
          afterChange    : tags.afterChange || null,
          getNewSourceFn : tags.getNewSourceFn || function(){} // not actually used but needs to be non-null for now
        },
        container       = document.getElementById(config.containerId),
        slideCount      = -1,
        currentIndex    = -1,
        slides,
        activeEl;
        

    var getPrevIndex = function(index) {
      return currentIndex === 0 ? slides.length - 1 : currentIndex - 1;
    };

    var getNextIndex = function(index) {
      return currentIndex === slides.length - 1 ? 0 : currentIndex + 1;
    };

    var updateCount = function() {
      document.getElementById(config.counterId).textContent = (currentIndex + 1) + ' / ' + slides.length;
    };

    var updateCaption = function() {
      var capDest = document.getElementById(config.capDestId);
      util.emptyNode(capDest);
      capDest.appendChild(slides[currentIndex].getElementsByClassName(config.capSourceClass)[0].cloneNode(true));
    };

    var initNewImage = function() {
      var newImage = this, oldImage = newImage.oldImg;
      this.onload = null;
      if(oldImage) {
        oldImage.parentNode.insertBefore(newImage, null);
        oldImage.parentNode.removeChild(oldImage);
      }
      
    };

    var loadNewSource = function(index) {
      var oldImg = slides[index].querySelector('img');
      if(!util.hasClass(oldImg, 'loaded') && config.getNewSourceFn !== null) {
        var src = oldImg.getAttribute('data-src');
        var request = new XMLHttpRequest();
        request.open('GET', src, true);
        request.onload = function() {
          if(request.status >= 200 && request.status < 400) {
            var i = new Image();
            i.src = src;
            i.className = 'loaded';
            i.oldImg = oldImg;
            i.idx = index;
            i.onload = initNewImage;
          } else {
            console.log('Server returned error code: ' + request.status);
          }
        };
        request.onerror = function() {
          console.log('Connection error');
        };
        request.send();
      }
    };

    var loadSlide = function(index) {
      if(index < slides.length) {
        loadNewSource(index);
        if (config.beforeChange) config.beforeChange();
        if (currentIndex !== -1) util.removeClass(activeEl, config.activeClass);
        currentIndex = index;
        util.addClass(slides[currentIndex], config.activeClass);
        activeEl = slides[currentIndex];
        updateCount();
        if (config.copyCaptions) updateCaption();
        if (config.afterChange) config.afterChange(activeEl);
      }
    };

    var showNext = function() {
      loadSlide(getNextIndex(currentIndex));
    };

    var showPrev = function() {
      loadSlide(getPrevIndex(currentIndex));
    };

    var init = function() {
      loadSlide(config.startAtIndex);
      util.addSwipeEvents(container);
      util.addEventListener(document, 'keydown', function(e) {
        if (e.which === 39) showNext();
        else if (e.which === 37) showPrev();
      });
      util.addEventListener(document.getElementById(config.nextId), 'click', showNext);
      util.addEventListener(document.getElementById(config.prevId), 'click', showPrev);
      util.addEventListener(container, 'swipeleft', showNext);
      util.addEventListener(container, 'swiperight', showPrev);
    };

    if (!!container) {
      slides = container.getElementsByClassName(config.slideClass);
      slideCount = slides.length;
      if(slides.length !== 0) {
        init();
      }
    }
    

    /* PUBLIC */
    this.loadSlide = loadSlide;
    this.showNext = showNext;
    
  };
}(window.app, window.app.util));