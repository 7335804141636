'use strict';
/* jslint browser: true */

(function(scope, util) {

  scope.Slider = function (ops) {
    var config                = {
          containerId         : ops.containerId || 'artist-slider',
          itemScrollerClass   : ops.itemScrollerClass || 'item-scroller',
          itemWrapperClass    : ops.itemWrapperClass || 'item-wrapper',
          navBackClass        : ops.navBackClass || 'prev',
          itemClass           : ops.itemClass || 'item',
          navForwardClass     : ops.navForwardClass || 'next',
          sameHeight          : ops.sameHeight || true,
          margin              : ops.margin || 5,
          loadedClass         : ops.loadedClass || 'loaded',
          navVisibleClass     : ops.navVisibleClass || 'visible',
          navHiddenClass      : ops.navHiddenClass || 'hidden',
          navDisabledClass    : ops.navHiddenClass || 'disabled',
          clickFunction       : ops.clickFunction || null
        },
        containerEl           = document.getElementById(config.containerId),
        scrollBackInterval    = null, 
        scrollForwardInterval = null,
        loadedCount           = 0, 
        errorCount            = 0,
        maxScroll             = 0,
        //itemWidths            = [],  
        //scrollPositions       = [],
        //currentScrollIndex    = 0,  
        images, 
        imageCount,
        itemWrapperEl,
        itemScrollerEl,
        navBackEl,
        navForwardEl;

    var scrollDiv = function(sign, increment) {
      itemScrollerEl.scrollLeft += sign * increment;
    };

    var stopScrollBack = function() {
      clearInterval(scrollBackInterval);
    };

    var stopScrollForward = function() {
      clearInterval(scrollForwardInterval);
    };

    var scrollBack = function() {
      if(itemScrollerEl.scrollLeft !== 0) {
        //util.removeClass(navForwardEl, 'disabled');
        scrollBackInterval = setInterval(function(){
          scrollDiv(-1, 4);
        }, 15);
      }
    };

    var scrollForward = function() {
      if(itemScrollerEl.scrollLeft !== maxScroll) {
        //util.removeClass(navForwardEl, 'disabled');
        scrollForwardInterval = setInterval(function(){
          scrollDiv(1, 4);
        }, 15);
      }
    };

    var getImageItemHolder = function(img) {
      var parent = img.parentNode;
      while(!util.hasClass(parent, config.itemClass)) {
        parent = parent.parentNode;
      }
      return parent;
    };

    // if image too big to fit in holder view, not much that can be done to fix
    //  todo:
    //  smooth scroll
    //  check if scroll is possible
    //  disable buttons when no scroll
    //  resize resets
    //  what about when user scrolls manually?

//    var scrollForward = function() {
//      var itemToScrollTo = getImageItemHolder(images[scrollPositions[currentScrollIndex + 1]]);
//      itemScrollerEl.scrollLeft = itemToScrollTo.offsetLeft;
//      currentScrollIndex++;
//    };

    var scrolled = function() {
      if(itemScrollerEl.scrollLeft === 0) {
        util.addClass(navBackEl, 'disabled');
        stopScrollBack();
      } else if(itemScrollerEl.scrollLeft >= maxScroll) {
        util.addClass(navForwardEl, 'disabled');
        stopScrollForward();
      } else {
        util.removeClass(navBackEl, 'disabled');
        util.removeClass(navForwardEl, 'disabled');
      }
    };

    var addEventListeners = function(){
      util.addEventListener(itemScrollerEl, 'scroll', scrolled);
      //util.addEventListener(navForwardEl, 'click', scrollForward);
      util.addEventListener(navBackEl, 'mouseenter', scrollBack);
      util.addEventListener(navForwardEl, 'mouseenter', scrollForward);
      util.addEventListener(navBackEl, 'mouseleave', stopScrollBack);
      util.addEventListener(navForwardEl, 'mouseleave', stopScrollForward);
    };

    var removeEventListeners = function(){
      util.removeEventListener(navBackEl, 'mouseenter', scrollBack);
      util.removeEventListener(navForwardEl, 'mouseenter', scrollForward);
      util.removeEventListener(navBackEl, 'mouseleave', stopScrollBack);
      util.removeEventListener(navForwardEl, 'mouseleave', stopScrollForward);
    };


    var equalizeHeights = function() {
      util.forEach(images, function(img) {
        var parent = getImageItemHolder(img), w;
        w = img.getBoundingClientRect().width + 2*config.margin;
        parent.style.width = w + 'px';
        //itemWidths.push(w);
      });
    };

//    var calculateScrollPositions = function() {
//      var holderWidth = itemScrollerEl.getBoundingClientRect().width,
//          visibleWidth = itemWidths[0]; //first image 
//      scrollPositions.push(0); // starting positions
//      for(var i = 1; i < imageCount; i++) {
//        if (visibleWidth + itemWidths[i] > holderWidth) {
//          scrollPositions.push(i);
//          visibleWidth = 0;
//        }
//        visibleWidth += itemWidths[i];
//      }
//    };


    var handleWidth = function() {
      //console.log('hw');
      var containerW = containerEl.getBoundingClientRect().width;
      var itemWrapperW = itemWrapperEl.getBoundingClientRect().width;
      //console.log(itemWrapperW);
      //alert(window.sessionStorage.getItem('touch'));
      if(itemWrapperW > containerW && !util.isTouch()) {
        var navWidth = navBackEl.getBoundingClientRect().width + navForwardEl.getBoundingClientRect().width;
        itemScrollerEl.style.width = '' + (containerW - navWidth) + 'px';
        itemScrollerEl.style.left = navBackEl.getBoundingClientRect().width + 'px';
        util.forEach([navBackEl, navForwardEl], function(el) {
          util.addClass(el, config.navVisibleClass);
          util.removeClass(el, config.navHiddenClass);
        });
        maxScroll = Math.floor(itemWrapperW - (containerW - navWidth));
        util.addClass(navBackEl, config.navDisabledClass);
        addEventListeners();

      } else {

        itemScrollerEl.style.width = '' + containerW + 'px';
        itemScrollerEl.style.left = '0px';
        util.forEach([navBackEl, navForwardEl], function(el) {
          util.addClass(el, config.navHiddenClass);
          util.removeClass(el, config.navVisibleClass);
        });
        removeEventListeners();
      }
    };

    var init = function() {
      if (config.sameHeight) equalizeHeights();
      handleWidth();
      //calculateScrollPositions();
      util.addClass(containerEl, config.loadedClass);
      util.addEventListener(window, 'resize', handleWidth);
      util.addEventListener(window, 'resize', equalizeHeights);
    };

    var areAllLoaded = function(e) {
      if(e.type === 'load' || e.type === 'onload') {
        loadedCount += 1;
      } else if (e.type === 'error') {
        errorCount += 1;
      }
      if (loadedCount + errorCount === imageCount) {
        init();
      }
    };

    var waitForImages = function() {
      util.forEach(images, function(img, i) {
        if (img.complete) {
          loadedCount += 1;
          areAllLoaded(new Event('null'));
        } else {
          util.addEventListener(img, 'load', areAllLoaded);
          util.addEventListener(img, 'error', areAllLoaded);
        }
      });
    };


    if (!!containerEl) {
      itemWrapperEl  = containerEl.getElementsByClassName(config.itemWrapperClass)[0];
      itemScrollerEl = containerEl.getElementsByClassName(config.itemScrollerClass)[0];
      navBackEl      = containerEl.getElementsByClassName(config.navBackClass)[0];
      navForwardEl   = containerEl.getElementsByClassName(config.navForwardClass)[0];
      images = itemWrapperEl.getElementsByTagName('img');
      imageCount = images.length;
      if (imageCount !== 0) waitForImages();
    }

  };

}(window.app, window.app.util));