'use strict';
/* jslint browser: true */

(function(scope) {


  
  scope.util = {
    isTouch : function() {
      return !!(window.localStorage.getItem('touch'));
    },
    forEach : function(array, fn) {
      var i, length = array.length;
      if (array.length !== 0) {
        for (i = 0; i < length; i++) {
          fn(array[i], i);
        }
      }
    },
    emptyNode : function(el) {
      while(el.firstChild) el.removeChild(el.firstChild);
    },
    addClass : function(el, className) {
      if (el.classList) {
        el.classList.add(className);
      } else {
        el.className += ' ' + className;
      }
    },
    removeClass : function(el, className) {
      if (el.classList) {
        el.classList.remove(className);
      } else {
        el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
      }
    },
    hasClass : function(el, className) {
      if (el.classList) {
        return el.classList.contains(className);
      } else {
        return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
      }
    },
    addEventListener : function(el, eventName, handler) {
      if (el.addEventListener) {
        el.addEventListener(eventName, handler);
      } else {
        el.attachEvent('on' + eventName, function(){
          handler.call(el);
        });
      }
    },
    removeEventListener : function (el, eventName, handler) {
      if (el.removeEventListener) {
        el.removeEventListener(eventName, handler);
      } else {
        el.detachEvent('on' + eventName, handler);
      }
    },
    // add detection for y-move to ensure left-right swipe not fired
    addSwipeEvents: function(el) {
      el.addEventListener('touchstart', handleTouchStart, false);        
      el.addEventListener('touchmove', handleTouchMove, false);
      var swipeLeft = new Event('swipeleft');
      var swipeRight = new Event('swiperight');
      var xDown = null; 
      var yDown = null;                                                        
      function handleTouchStart(evt) {                                         
        xDown = evt.touches[0].clientX;
        yDown = evt.touches[0].clientY;                                      
      }                                               
      function handleTouchMove(evt) {
        if ( ! xDown ) return;
        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;                                    
        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;
        if (Math.abs(xDiff) > Math.abs(yDiff)) {
          evt.stopPropagation();
          if ( xDiff > 0 ) {
            el.dispatchEvent(swipeLeft);
          } else {
            el.dispatchEvent(swipeRight);
          }
        }                  
        xDown = null;
        yDown = null;
      }
    },
    windowWidth : function() {
      return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    },
    windowHeight : function() {
      return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    }
  };
}(window.app));