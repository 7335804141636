'use strict';
/* jslint browser: true */

(function(scope, util) {

  scope.Tabs = function(ops) {
    var config = {
          tabTriggerClass        : ops.tabTriggerClass || 'tab',
          activeClass            : ops.activeClass || 'active',
          tabContentClass        : ops.tabContentClass || 'tab-content',
          tabIdentifierAttribute : ops.tabIdentifierAttribute || 'data-tab-index',
          beforeChange           : ops.beforeChange || null,
          afterChange            : ops.afterChange || null,
          startAt                : ops.startAt || null,
          toggler                : ops.toggler || false
        },
        tabTriggers = document.getElementsByClassName(config.tabTriggerClass),
        tabContents = document.getElementsByClassName(config.tabContentClass),
        currentIndex = config.startAt || 0;

    var activate = function() {
      if (config.beforeChange) config.beforeChange();
      util.addClass(tabTriggers[currentIndex], config.activeClass);
      util.addClass(tabContents[currentIndex], config.activeClass);
    };

    var deactivate = function() {
      if (currentIndex !== -1 ) {
        util.removeClass(tabTriggers[currentIndex], config.activeClass);
        util.removeClass(tabContents[currentIndex], config.activeClass);
        if (config.afterChange) config.afterChange();
      }
    };


    var showTab = function(e) {
      // if toggler and current active = index, then only deactivate
      var index = this.getAttribute(config.tabIdentifierAttribute);
      if(util.hasClass(this, config.activeClass) && config.toggler) {
        deactivate();
        currentIndex = -1;
      } else {
        deactivate();
        currentIndex = index;
        activate();
      }
    };

    var loadTab = function(index) {
      deactivate();
      currentIndex = index;
      activate();
    };

    var init = function() {
      activate();
      util.forEach(tabTriggers, function(el){
        util.addEventListener(el, 'click', showTab);
      });
    }

    if (tabTriggers.length !== 0 && tabContents.length !== 0) {
      init();
    } 
    
    // public
    this.loadTab = loadTab;
    this.deactivate = deactivate;
  };

}(window.app, window.app.util));