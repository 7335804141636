'use strict';
/* jslint browser: true */
/* global app */

var htmlClass = document.documentElement.className;
var overlay = document.getElementById('overlay');

if(app.util.isTouch()) {
  app.util.addClass(document.documentElement, 'touch');
}

app.util.addEventListener(document.getElementById('menu'), 'click', function(){
  app.util.addClass(document.documentElement, 'no-scroll');
  app.util.addClass(overlay, 'active');
});


app.util.addEventListener(document.getElementById('close'), 'click', function(){
  app.util.removeClass(document.documentElement, 'no-scroll');
  app.util.removeClass(overlay, 'active');
});


if (!!document.getElementById('price-form')) {
  var requestPrice    = document.getElementById('request'),
      formModal       = document.getElementById('form-modal'),
      form            = document.getElementById('price-form');
  var f = new app.Form({
    form : form,
    onload: function(form, request) {
      var cover = form.getElementsByClassName('cover')[0];
      if(request.status === 500) {
        app.util.removeClass(cover, 'loading');
        app.util.addClass(document.getElementById('server'), 'visible');
      } else if(request.status === 200){
        form.reset();
        app.util.removeClass(cover, 'loading');
        app.util.addClass(cover, 'success');
        setTimeout(function() {
          app.util.removeClass(cover, 'success');
          app.util.removeClass(formModal, 'active');
        }, 1500);
      }
    }
  });

  app.util.addEventListener(requestPrice, 'click', function(e) {
    var active = document.querySelector('.slide.active');
    var str = 'Hello\n\nMay I please get more information (price, year) for the work ';
    str = str + active.getAttribute('data-artist') + '/' + active.getAttribute('data-code');
    str = str + '\n\nThank you';
    form.querySelector('textarea').value =  str;
    app.util.addClass(formModal, 'active');
  });

  app.util.addEventListener(formModal.querySelector('.close'), 'click', function(e) {
    app.util.removeClass(formModal, 'active');
  });

  app.util.addEventListener(form, 'click', function(e) {
    e.stopPropagation();
  });

  app.util.addEventListener(formModal, 'click', function(e) {
    app.util.removeClass(formModal, 'active');
  });
}




// HOMEPAGE ----------------------------------------------- 
if(htmlClass.match(new RegExp(/^homepage$/))) {
  var bgs = document.getElementsByClassName('bg'),
      currentIndex = 0;
  setTimeout(function() {
    setInterval(function() {
      app.util.removeClass(bgs[currentIndex], 'active');
      currentIndex = currentIndex === bgs.length - 1 ? 0 : currentIndex+1;
      app.util.addClass(bgs[currentIndex], 'active');
    }, 5500);
  }, 4900); // interval - transition duration
}




// EXHIBITIONS COLLECTION ----------------------------------------------- 
else if(htmlClass.match(new RegExp(/^exhibitions$/))) {
  var exs = document.getElementsByClassName('slider');
  app.util.forEach(exs, function(ex) {
    new app.Slider({
      containerId : ex.getAttribute('id'),
      sameHeight: true
    });
  });
  app.util.forEach(document.getElementsByClassName('link'), function(el) {
    app.util.addEventListener(el, 'click', function(e) {
      e.preventDefault();
      window.location.href = this.href + '/!#' + this.getAttribute('data-index'); 
    });
  });
}




// ARTISTS COLLECTION -----------------------------------------------
else if (htmlClass.match(new RegExp(/^artists$/))) {
  var f = new app.Filter({
    containerId : 'grid-view',
    contentClass: 'grid-item'
  });
  var f2 = new app.Filter({
    containerId : 'list-view',
    contentClass: 'list-item'
  });
  var t = new app.Tabs({
    beforeChange: function() {
      var tcs = document.getElementsByClassName('tabs-content');
      app.util.forEach(tcs, function(el, i){
        tcs[i].scrollTop = 0;
      });
      f.reinit();
      f2.reinit();
    }
  });
}




// ARTIST ----------------------------------------------- 
else if(htmlClass.match(new RegExp(/^artist$/))) {
  
  var s = new app.Slider({
    containerId : 'slider-artist-available',
    sameHeight: true
  });

  app.util.forEach(document.getElementsByClassName('link'), function(el, i) {
    app.util.addEventListener(el, 'click', function(e) {
      e.preventDefault();
      window.location.href = this.href + '/' + this.getAttribute('data-code'); 
    })
  });

  var s2 = new app.Slider({
    containerId : 'slider-artist-previous',
    sameHeight: true
  });

  var t = new app.Tabs({

  });
}




// EXHIBITION -----------------------------------------------  
else if (htmlClass.match(new RegExp(/^exhibition$/))) {
  var t, z, ss, workId = window.location.hash, 
  tabs = document.getElementsByClassName('tabs'),
  worksExist = document.getElementsByClassName('slideshow').length !== 0,
  tabsExist = tabs.length !== 0,
  tabOffset = tabsExist ? tabs[0].offsetTop : null,
  scrTop = 0; 


  if(tabsExist) {
    t = new app.Tabs({});
  }

  if(worksExist) {
    z = new app.Zoomer({
      beforeOpen: function() { app.util.addClass(document.documentElement, 'no-scroll');},
      afterClose: function() { app.util.removeClass(document.documentElement, 'no-scroll');},
      getNewSourceFn : function(str) {
        return str.replace(/tiny|small|medium|large/, 'xl');
      }
    });

    if(workId.length !== 0) {  
      ss = new app.Slideshow({
        containerId     : 'slideshow-exhibition',
        beforeChange    : function() { z.close(); },
        startAtIndex    : parseInt(workId.substr(1)),
        getNewSourceFn  : function(str) {
          return str.replace(/small|medium/, 'large');
        }
      });
      if(tabsExist) {
        var scr = function() {
          if(document.body.scrollTop < tabOffset - 66) { // nav height + 11 .. magic number
            document.body.scrollTop += 8;
            setTimeout(scr, 2);
          }
        };

        setTimeout(scr, 1000);
      }
    } else {
      ss = new app.Slideshow({
        containerId     : 'slideshow-exhibition',
        beforeChange    : function() { z.close(); },
        getNewSourceFn  : function(str) {
          return str.replace(/small|medium/, 'large');
        }
      });
    }

    if(tabsExist && !app.util.isTouch()) {
      app.util.addEventListener(document, 'scroll', function() {
        if(document.body.scrollTop < tabOffset - 66) { // nav height + 11 .. magic number
          app.util.removeClass(document.getElementsByClassName('tabs-content')[0], 'scroll');
        } else {
          app.util.addClass(document.getElementsByClassName('tabs-content')[0], 'scroll');
        }
      });
    }

    app.util.addEventListener(document.getElementById('zoom-trigger'), 'click', function() {
      var el = document.querySelector('.slide.active img');
      z.zoom(el);
    });

    app.util.forEach(document.getElementsByClassName('slide'), function(el) {
      app.util.addEventListener(el, 'click', function(e) {
        var elToZoom = e.target.tagName.toLowerCase() == 'img' ? e.target : null;
        z.zoom(elToZoom);
      });
    });

  }

  

}




// AVAILABLE WORKS -----------------------------------------------
else if (htmlClass.match(new RegExp(/^available-works$/))) {
  var hash            = window.location.hash;
  var f = new app.Filter({
    containerId : 'grid-view',
    contentClass: 'grid-item'
  });
  var f2 = new app.Filter({
    containerId : 'list-view',
    contentClass: 'list-item'
  });
  var t = new app.Tabs({
    beforeChange: function() {
      var tcs = document.getElementsByClassName('tabs-content');
      app.util.forEach(tcs, function(el, i){
        tcs[i].scrollTop = 0;
      });
      f.reinit();
      f2.reinit();
    }
  });
  if(hash.length !== 0) {   
    hash = hash.substr(1);
    hash = hash === '1' || hash === '0' ? hash : '0';
    t.loadTab(parseInt(hash));
  }
}




// AVAILABLE WORKS SLIDESHOW-----------------------------------------------
else if (htmlClass.match(new RegExp(/^available-works-slideshow$/))) {
  var hash            = window.location.hash, 
      urlParts        = [],
      breadcrumb     = document.querySelector('#breadcrumbs .current a');

  var z = new app.Zoomer({
    beforeOpen: function() { app.util.addClass(document.documentElement, 'no-scroll');},
    afterClose: function() { app.util.removeClass(document.documentElement, 'no-scroll');},
    getNewSourceFn : function(str) {
      return str.replace(/tiny|small|medium|large/, 'xl');
    }
  });


  app.util.addEventListener(document.getElementById('zoom-trigger'), 'click', function() {
    var el = document.querySelector('.slide.active img');
    z.zoom(el);
  });

  app.util.forEach(document.getElementsByClassName('slide'), function(el) {
    app.util.addEventListener(el, 'click', function(e) {
      var elToZoom = e.target.tagName.toLowerCase() == 'img' ? e.target : null;
      z.zoom(elToZoom);
    });
  });

  var ss = new app.Slideshow({
    containerId     : 'slideshow-available-works',
    slideClass      : 'slide-visible',
    beforeChange    : function() { z.close(); },
    afterChange     : function(newEl) {
      window.location.hash = '/' + newEl.getAttribute('data-artist') + '/' + newEl.getAttribute('data-code');
      breadcrumb.textContent = newEl.getAttribute('data-name');
    },
    getNewSourceFn  : function(str) {
      return str.replace(/tiny|mini|small|medium/, 'large');
    }
  });

  // 'arr' is array of artist names, initialized in jade template
  app.util.addEventListener(document.getElementById('filter'), 'keyup', function(e) {
    if((e.keyCode >= 65 && e.keyCode <= 90) || e.keyCode === 32 || e.keyCode === 8 || e.keyCode === 46) {
      var query = this.value.toLowerCase(), count = arr.length;
      for(var i = 0; i < count; i++) {
        if(arr[i].indexOf(query) !== -1) {
          ss.loadSlide(i);
          break;
        }
      }
    }
  });


  if(hash.length !== 0) {
    hash = hash.substr(1);
    urlParts = hash.split('/');
    urlParts.shift();
    if(urlParts.length === 1) {
      var el = document.querySelector('[data-artist="'+urlParts[0]+'"]');
      ss.loadSlide(parseInt(el.getAttribute('data-index')));
    } else if (urlParts.length === 2) {
      var el = document.querySelector('[data-artist="'+urlParts[0]+'"][data-code="'+urlParts[1]+'"]');
      ss.loadSlide(parseInt(el.getAttribute('data-index')));
    }

  }
}


// COLLECTION SLIDESHOW-----------------------------------------------
else if (htmlClass.match(new RegExp(/^collection-slideshow$/))) {
  var hash            = window.location.hash, 
      urlParts        = [],
      breadcrumb     = document.querySelector('#breadcrumbs .current a');

  var z = new app.Zoomer({
    beforeOpen: function() { app.util.addClass(document.documentElement, 'no-scroll');},
    afterClose: function() { app.util.removeClass(document.documentElement, 'no-scroll');},
    getNewSourceFn : function(str) {
      return str.replace(/tiny|small|medium|large/, 'xl');
    }
  });


  app.util.addEventListener(document.getElementById('zoom-trigger'), 'click', function() {
    var el = document.querySelector('.slide.active img');
    z.zoom(el);
  });

  app.util.forEach(document.getElementsByClassName('slide'), function(el) {
    app.util.addEventListener(el, 'click', function(e) {
      var elToZoom = e.target.tagName.toLowerCase() == 'img' ? e.target : null;
      z.zoom(elToZoom);
    });
  });

  var ss = new app.Slideshow({
    containerId     : 'slideshow-collection-works',
    slideClass      : 'slide-visible',
    beforeChange    : function() { z.close(); },
    afterChange     : function(newEl) {
      window.location.hash = '/' + newEl.getAttribute('data-artist') + '/' + newEl.getAttribute('data-code');
      // breadcrumb.textContent = newEl.getAttribute('data-name');
    },
    getNewSourceFn  : function(str) {
      return str.replace(/tiny|mini|small|medium/, 'large');
    }
  });


  if(hash.length !== 0) {
    hash = hash.substr(1);
    urlParts = hash.split('/');
    urlParts.shift();
    if(urlParts.length === 1) {
      var el = document.querySelector('[data-artist="'+urlParts[0]+'"]');
      ss.loadSlide(parseInt(el.getAttribute('data-index')));
    } else if (urlParts.length === 2) {
      var el = document.querySelector('[data-artist="'+urlParts[0]+'"][data-code="'+urlParts[1]+'"]');
      ss.loadSlide(parseInt(el.getAttribute('data-index')));
    }

  }
}



// SEARCH -----------------------------------------------
else if(htmlClass.match(new RegExp(/^search$/))) {
  var exs = document.getElementsByClassName('slider');
  app.util.forEach(exs, function(ex) {
    new app.Slider({
      containerId : ex.getAttribute('id'),
      sameHeight: true,
      setHeightTo: 350
    });
  });
  app.util.forEach(document.getElementsByClassName('link'), function(el) {
    app.util.addEventListener(el, 'click', function(e) {
      e.preventDefault();
      window.location.href = this.href + '/!#' + this.getAttribute('data-index'); 
    });
  });
}



// CONTACT -----------------------------------------------
else if(htmlClass.match(new RegExp(/^contact$/))) {
  new app.Form({
    form : document.getElementById('contact-form')
  });
}

